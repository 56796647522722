<template>
  <div id="menu01" class="cts_box anchor_point">
    <h2 class="sub_ttl"><span>現在のポイント残高</span></h2>

    <div class="bdr_box">
      <dl class="point_zandaka">
        <dt>現在のポイント残高</dt>
        <dd><span class="blue">{{ delimited(point.total_points)}}</span> ポイント</dd>
      </dl>
      <dl class="point_zandaka">
        <dt>次回失効ポイント</dt>
        <dd><span class="red">{{ point.expire ? delimited(point.expire.point) : 0}}</span> ポイント</dd>
      </dl>
      <dl class="point_zandaka">
        <dt class="sml">次回失効予定日</dt>
        <dd><span class="data">{{ point.expire ? moment(point.expire.date, 'YYYY年MM月DD日') : '-' }}</span></dd>
      </dl>
    </div>

    <div class="button_wrap">
      <p>ポイント履歴を確認するにはこちらのボタンを押してください。</p>
      <router-link :to="{ name: 'UsersCustomerPoint'}" class="submit light skeleton">ポイント通帳</router-link>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  props: {
    point: null
  }
}
</script>