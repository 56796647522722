<template>
  <div id="container">
    <section id="mypage" v-if="fetch_data">
      <div class="wrap">

        <StandBy v-if="!isUserJoin()"/>

        <div class="cts_box">
          <h2 class="main_ttl"><span>マイページ</span></h2>
          <p class="lead_txt">
            <template v-if="current_user.sns_rank === 'rank_student'">
              マイページでは、登録情報・クーポン履歴の確認、各種設定と活動状況、研修会への参加・オンラインショップでの購入履歴などをご覧いただけます。
            </template>
            <template v-else>
              マイページでは、登録情報・ポイント残高・クーポン履歴の確認、各種設定と活動状況、研修会への参加・オンラインショップでの購入履歴などをご覧いただけます。
            </template>
          </p>
        </div>

        <!-- 塾生情報 -->
        <UserInfo/>

        <!-- 認定資格 -->
        <Achievement :achievement="fetch_data.achievement"/>

        <!-- プロフィール -->
        <Profile v-if="!isUserWithdrawal()"/>

        <!-- お気に入り -->
        <Favorite v-if="!isUserWithdrawal() && fetch_data.favorites.count"/>

        <!-- 四季報オンライン -->
        <ShikihoOnline v-if="fetch_data.shikiho_online && !isUserSleep() && !isUserWithdrawal()" :shikiho_online="fetch_data.shikiho_online"/>

        <!-- お友達紹介プログラム -->
        <Affiliate v-if="false"/>

        <!-- 現在のポイント残高 -->
        <Point :point="fetch_data.point" v-if="!isUserWithdrawal() && current_user.sns_rank !== 'rank_student'"/>

        <!-- 現在所有しているクーポン -->
        <Coupon :coupons="fetch_data.coupons" v-if="fetch_data.coupons.length"/>

        <!-- 各種設定と活動状況 -->
        <SnsDashbord :sns="fetch_data.sns" v-if="isUserJoin() && !isUserSleep() && !isUserWithdrawal()"/>

        <!-- テーマ別グループ -->
        <SnsSocial v-if="isUserJoin() && !isUserSleep() && !isUserWithdrawal()"/>

        <!-- 研修会 -->
        <Workshop v-if="!isUserSleep() && !isUserWithdrawal()"/>

        <!-- オンラインショップ購入履歴 -->
        <Shop :shoppings="fetch_data.shop_ships" v-if="fetch_data.shop_ships.length > 0 && isUserJoin() && !isUserSleep() && !isUserWithdrawal()"/>

        <!-- 次回の月謝ご請求内容 -->
        <MonthlyFee :fee="fetch_data.fee" :point="fetch_data.point" :use_fee_points="fetch_data.user.use_fee_points"
                    v-if="fetch_data.fee && !isUserWithdrawal()"/>

        <!-- お支払い情報 -->
        <PaymentInfo v-if="current_user.credit_payment && !isUserWithdrawal()" v-on:update="cardUpdateClicked"/>

        <!-- お支払い履歴・領収書PDFダウンロード -->
        <PaymentHistory/>

        <!-- 休塾・退塾 -->
        <Taikai v-if="isUserJoin() && !isUserSleep()"/>

        <!-- 退塾 -->
        <Withdrawal v-if="isUserSleep() && !isUserWithdrawalRequest() "/>

        <!-- 休塾復帰 -->
        <UserSleepComback v-if="isUserSleep()"/>

        <!-- ログアウト -->
        <Logout/>

        <!-- 退塾(入塾待機中者) -->
        <template v-if="/*!isUserJoin()*/ false">
          <div class="cts_box">
            <h2 class="min_ttl"><span>複眼経済塾への入塾をキャンセルする場合はこちら</span></h2>
            <router-link :to="{name: 'UsersCustomerWithdrawal'}" class="link_txt">入塾キャンセル</router-link>
          </div>
        </template>

        <!-- サイト管理 -->
        <Staff v-if="isStaff()"/>
      </div>
    </section>
    <UpdatePaygentModal v-if="show_update_paygent_modal"
                        v-on:cancel="cardUpdatePaygentCanceled"
                        v-on:update="cardUpdatePaygentClicked"
    />

  </div>
</template>

<script>

import Libraries from '@/components/Libraries'
import UserInfo from '@/views/users/customer/elements/account/_user_info'
import Achievement from '@/views/users/customer/elements/account/_achievement'
import Profile from '@/views/users/customer/elements/account/_profile'
import ShikihoOnline from '@/views/users/customer/elements/account/_shikiho_online'
import Affiliate from '@/views/users/customer/elements/account/_affiliate'
import Point from '@/views/users/customer/elements/account/_point'
import Coupon from '@/views/users/customer/elements/account/_coupon'
import SnsDashbord from '@/views/users/customer/elements/account/_sns_dashbord'
import SnsSocial from '@/views/users/customer/elements/account/_sns_social'
import Workshop from '@/views/users/customer/elements/account/_workshop'
import Shop from '@/views/users/customer/elements/account/_shop'
import MonthlyFee from '@/views/users/customer/elements/account/_monthly_fee'
import PaymentInfo from '@/views/users/customer/elements/account/_payment_info'
import PaymentHistory from '@/views/users/customer/elements/account/_payment_history'
import Taikai from '@/views/users/customer/elements/account/_taikai'
import Withdrawal from '@/views/users/customer/elements/account/_withdrawal'
import Logout from '@/views/users/customer/elements/account/_logout'
import Staff from '@/views/users/customer/elements/account/_staff'
import StandBy from '@/views/users/customer/elements/account/_stand_by'
import UpdatePaygentModal from '@/views/users/customer/elements/account/_update_paygent_modal'
import UserSleepComback from '@/views/users/customer/elements/account/_user_sleep_comback'
import Favorite from '@/views/users/customer/elements/account/_favorite'

export default {
  mixins: [Libraries],
  components: {
    UserInfo,
    Achievement,
    Profile,
    ShikihoOnline,
    Affiliate,
    Point,
    Coupon,
    SnsDashbord,
    SnsSocial,
    Workshop,
    Shop,
    MonthlyFee,
    PaymentInfo,
    PaymentHistory,
    Taikai,
    Withdrawal,
    Logout,
    Staff,
    StandBy,
    UpdatePaygentModal,
    UserSleepComback,
    Favorite
  },
  data() {
    return {
      fetch_data: null,
      show_update_paygent_modal: false
    }
  },
  methods: {
    cardUpdateClicked() {
      this.show_update_paygent_modal = true
    },
    cardUpdatePaygentCanceled() {
      this.show_update_paygent_modal = false
    },
    cardUpdatePaygentClicked() {
      this.axios
          .post(`${this.env.api_base_url}users/card_update_paygent.json`)
          .then((response) => {
            document.location.href = response.data.url
          })
          .catch((error) => {
            alert(error.response.data.error)
            this.logout()
          });
    },
  },
  mounted() {
    this.startAjax()
    this.axios
        .get(`${this.env.api_base_url}users/account.json`)
        .then(response => {
          // console.log(response.data)
          this.fetch_data = response.data
          this.saveSession(response.data.user);
        })
        .catch((response) => {
          this.errorAjax(response)
        })
        .finally(() => {
          this.finishAjax()
          if (location.hash) {
            setTimeout(() => {
              this.scrollTo(location.hash)
            }, 200)
          }
        })
  }
}
</script>
