<template>
  <div class="keep_list flex">
    <template v-for="(rule, index) in keep_rules">
      <div class="keep_list_box now_challenge"
           v-if="dan_value >= rule.dan_value" :key="'rule-keep-' + index">
        <p>「{{ rule.space }}」<br>を1回視聴する</p>
        <ul class="check_list flex">
          <li class="" v-bind:class="{check: dan.detail.expire[rule.dan].completed_at}">
            <p v-if="dan.detail.expire[rule.dan].completed_at">{{
                moment(dan.detail.expire[rule.dan].completed_at, 'M/D')
              }}</p>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import Dans from '@/const/Achievements.js'

export default {
  mixins: [Libraries],
  data() {
    return {
      dan_value: Dans.DANS[this.dan.dan],
      keep_rules: [
        {
          dan: 'rank1', dan_value: Dans.RANK1, space: '5 whys 投資事始'
        },
        {
          dan: 'rank2', dan_value: Dans.RANK2, space: '塾長週報'
        }
      ]
    }
  },
  props: {
    dan: null
  }
}
</script>