<template>
  <div class="cts_box">
    <h2 class="sub_ttl"><span>テーマ別グループ</span></h2>
    <p class="lead_txt">
      参加していないグループへの投稿・コメントの書き込みは出来ません。グループに参加するためには、下のボタンから「テーマ別グループ一覧」を表示して、参加したいグループの「入室する」をクリックしてください。
    </p>

    <div class="button_wrap">
      <router-link :to="{ name: 'SnsSocials'}" class="submit light skeleton">テーマ別グループ一覧</router-link>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries]
}
</script>
