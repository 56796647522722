<template>
  <div class="cts_box achievement" v-if="current_user.rank !== 'rank_student_month'" v-bind:class="{achievement_disabled: !current_user.dan_enabled || !current_user.dan_join}">
    <h2 class="sub_ttl"><span>複眼経済塾認定資格</span></h2>

    <p class="lead_txt mb0">
      「複眼経済塾認定資格」では、これまでの受講状況に基づいて、学習の進み具合を「5級」から「五段」までの10段階で評価しています。
    </p>

    <div class="toggle_menu flex mb20">
      <div class="toggle_menu_list flex">
        <div class="list bold" style="max-width: 160px;">認定資格への参加</div>
        <template v-if="current_user.dan_enabled">
          <template v-if="current_user.dan_join">
            <div class="toggle_btn flex on">
              <p class="no">参加をやめる</p>
              <div class="toggle_wrap" @click="no_join_modal = true"><span></span></div>
              <p class="yes">参加する</p>
            </div>
          </template>
          <template v-else>
            <div class="toggle_btn flex">
              <p class="no">参加をやめる</p>
              <div class="toggle_wrap" @click="join_modal = true"><span></span></div>
              <p class="yes">参加する</p>
            </div>
          </template>
        </template>
      </div>
    </div>

    <div class="achievement_box">
      <p>現在の段位は「<span>{{ achievementLabel(current_user.dan) }}</span>」です。</p>
      <ul class="achievement_list flex">
        <li v-bind:class="rankClass('rank05')"><img src="/common/images/achievement/achievement_rank05.svg" alt="5級">
        </li>
        <li v-bind:class="rankClass('rank04')"><img src="/common/images/achievement/achievement_rank04.svg" alt="4級">
        </li>
        <li v-bind:class="rankClass('rank03')"><img src="/common/images/achievement/achievement_rank03.svg" alt="3級">
        </li>
        <li v-bind:class="rankClass('rank02')"><img src="/common/images/achievement/achievement_rank02.svg" alt="2級">
        </li>
        <li v-bind:class="rankClass('rank01')"><img src="/common/images/achievement/achievement_rank01.svg" alt="1級">
        </li>
        <li v-bind:class="rankClass('rank1')"><img src="/common/images/achievement/achievement_rank1.svg" alt="初段">
        </li>
        <li v-bind:class="rankClass('rank2')"><img src="/common/images/achievement/achievement_rank2.svg" alt="2段">
        </li>
        <li v-bind:class="rankClass('rank3')"><img src="/common/images/achievement/achievement_rank3.svg" alt="3段">
        </li>
        <li v-bind:class="rankClass('rank4')"><img src="/common/images/achievement/achievement_rank4.svg" alt="4段">
        </li>
        <li v-bind:class="rankClass('rank5')"><img src="/common/images/achievement/achievement_rank5.svg" alt="5段">
        </li>
      </ul>
    </div>
    <template v-if="current_user.dan_enabled">
      <!-- ↓初段以下は非表示-->
      <div class="keep_box" v-if="achievement.expire_month === true">
        <p class="red bold"><span>{{ achievementLabel(current_user.dan) }}</span>を維持するためには一定の条件を満たす必要があります。</p>
        <p class="date bold"><span class="red">{{ moment(achievement.expire_at, 'YYYY/M/D') }}</span>
          までに以下の条件をすべて完了してください。</p>
        <AchievementKeep :dan="achievement"/>
      </div>
      <div class="keep_box" v-else-if="achievement.expire_dan === true">
        {{ achievementLabel(current_user.dan) }}には維持基準が設定されています。維持基準は達成した月の翌月月初から表示されます。
      </div>
    </template>

    <template v-if="achievement.challenge">
      <!-- ↓5段になったら非表示-->
      <div class="theme_box">
        <p class="achievement_step_txt"
           v-if="current_user.dan === 'rank03' && current_user.sns_rank === 'rank_preparatory'">
          次の「2級」に挑戦するには本科にアップグレードする必要があります</p>
        <p class="achievement_step_txt"
           v-else-if="current_user.dan === 'rank3' && current_user.sns_rank !== 'rank_regular_plus'">
          次の「四段」に挑戦するには本科プラスにアップグレードする必要があります</p>
        <p v-else>次の「<span>{{ achievementLabel(achievement.challenge.dan) }}</span>」を達成するためには、あと
          <span>{{ achievement.challenge.zan_count }}</span> 件 {{ challengeGuide() }}する必要があります。
        </p>

        <ul class="theme_list flex">
          <li class="next_rank challenge" v-bind:class="achievement.challenge.dan"></li>
          <li class="check_list"
              v-bind:class="{clear: n <= achievement.challenge.completed, 'no-entry': n > achievement.challenge.completed + 1}"
              v-for="n of achievement.challenge.clear_count" :key="n"></li>
        </ul>
      </div>
    </template>

    <div class="button_wrap" v-if="!current_user.sleep">
      <p>受講しなければならない講義の詳細はこちらをご確認ください</p>
      <router-link :to="{name: 'UsersCustomerAchievement'}" class="submit">認定資格 達成状況の確認</router-link>
    </div>

    <div class="modal" v-bind:class="{open: no_join_modal}">
      <div class="modal_box">
        <div class="header">
          <h4>認定資格への参加をやめる</h4>
          <div class="close modal_close" v-on:click="no_join_modal = false"></div>
        </div>
        <div class="inner">
          <p>
            認定資格への参加をやめるには、以下の「参加をやめる」をクリックしてください。<br>
            再開はいつでも可能です。
          </p>
          <div class="btn_wrap">
            <a href="javascript:void(0);" class="submit light"
               v-on:click="danNojoin()">参加をやめる</a>
          </div>
          <div class="btn_wrap">
            <a href="javascript:void(0);" class="submit gray modal_close"
               v-on:click="no_join_modal = false">キャンセル</a>
          </div>
        </div>
      </div>
      <div class="ovr"></div>
    </div>

    <div class="modal" v-bind:class="{open: join_modal}">
      <div class="modal_box">
        <div class="header">
          <h4>認定資格を再開する</h4>
          <div class="close modal_close" v-on:click="join_modal = false"></div>
        </div>
        <div class="inner">
          <p>
            認定資格再開するには、以下の「再開する」をクリックしてください。<br>
            以前の段位より再開可能ですが、維持基準がある場合は降級した級からとなります。
          </p>
          <div class="btn_wrap">
            <a href="javascript:void(0);" class="submit light"
               v-on:click="danjoin()">再開する</a>
          </div>
          <div class="btn_wrap">
            <a href="javascript:void(0);" class="submit gray modal_close"
               v-on:click="join_modal = false">キャンセル</a>
          </div>
        </div>
      </div>
      <div class="ovr"></div>
    </div>

  </div>
</template>

<script>
import Libraries from '@/components/Libraries'
import AchievementKeep from '@/views/users/customer/elements/account/_achievement_keep'

export default {
  mixins: [Libraries],
  components: {
    AchievementKeep
  },
  props: {
    achievement: null
  },

  data() {
    return {
      no_join_modal: false,
      join_modal: false,
    }
  },

  methods: {
    rankClass(checkRank) {
      const now_index = this.dan_array.indexOf(this.current_user.dan)
      const check_index = this.dan_array.indexOf(checkRank)
      let addClass = []
      if (now_index === check_index) {
        addClass.push('now_rank')
      } else if (now_index + 1 === check_index) {
        addClass.push('challenge')
      }
      if (now_index + 1 <= check_index) {
        addClass.push('no_clear')
      }
      return addClass
    },
    challengeGuide() {
      if (this.achievement.challenge.dan === 'rank2') {
        return '月例会 へ現地参加'
      } else if (this.achievement.challenge.dan === 'rank01') {
        return '研修会 へ参加'
      } else if (this.achievement.challenge.dan === 'rank3') {
        return '複眼合宿 へ参加'
      } else if (this.achievement.challenge.dan === 'rank4') {
        return '前夜祭 へ参加'
      } else if (this.achievement.challenge.dan === 'rank5') {
        return '複眼ツアー へ参加'
      }
      return '講義を受講'
    },
    danNojoin() {
      this.current_user.dan_join = false
      this.update()
    },
    danjoin() {
      this.current_user.dan_join = true
      this.update()
    },
    update() {
      this.startAjax()
      this.axios
          .post(`${this.env.api_base_url}users/update.json`, {
            user: this.current_user,
          })
          .then(response => {
            //console.log(response.data)
            this.saveSession(response.data.user);
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
            document.location.reload()
          })
    },
  }
}
</script>