<template>
  <div id="shopping" class="cts_box anchor_point">
    <h2 class="sub_ttl"><span>ショップ購入履歴</span></h2>
    <p class="lead_txt">
      ご注文いただいている商品のうち、現在未発送のものを表示しています。
    </p>


    <div class="online_shop_box">
      <div class="order_group" v-for="ship in shoppings" v-bind:key="ship.id" v-bind:class="{cancel: ship.status === 'status_cancel'}">
        <ul class="online_shop_list">
          <li v-for="(article, index) in ship.articles" v-bind:key="ship.id + '-' + article.id">
            <div class="buy_data flex">
              <table>
                <tbody>
                <tr class="head">
                  <th class="date">ご注文日</th>
                  <th class="price">金額</th>
                  <th class="task"></th>
                  <th class="address">お届け先</th>
                </tr>
                <tr>
                  <td class="date">{{ moment(ship.updated_at, 'YYYY年MM月DD日') }}</td>
                  <td class="price">¥ {{ delimited(article.price) }}</td>
                  <td class="task" v-if="ship.status === 'status_payment_waiting' || ship.status === 'status_bank_waiting'"><span
                      class="unpaid">代金支払待</span></td>
                  <td class="task" v-if="ship.status === 'status_shipping' || ship.status === 'status_new_order'"><span class="paid">商品発送待ち</span>
                  </td>
                  <td class="task" v-if="ship.status === 'status_cancel'"><span
                          class="error">キャンセル</span>
                  </td>
                  <td class="task" v-if="ship.status === 'status_payment_failure'"><span
                      class="error">決済失敗</span></td>
                  <td class="address">{{ `${ship.last_name} ${ship.first_name}` }} 様</td>
                </tr>
                </tbody>
              </table>
              <div class="btn pc" v-if="index === 0">
                <a href="javascript:void(0)" @click="onShoppingDetailClicked(ship)" class="linkBtn">注文内容詳細</a>
              </div>
              <div class="btn pc" v-if="index === 0 && ship.user_receipt_id===123456">
                <a href="javascript:void(0)" @click="receiptDownload(ship)" class="linkBtn">領収書ダウンロード</a>
              </div>
            </div>
            <div class="detail flex">
              <div class="icon">
                <router-link :to="{ name: 'ShoppingArticle', params: {id: article.id} }">
                  <img v-bind:src="article.image_files[0]" alt="">
                </router-link>
              </div>
              <div class="contents">
                <router-link :to="{ name: 'ShoppingArticle', params: {id: article.id} }">
                  <h3>{{ article.title }}</h3>
                </router-link>
              </div>
              <div class="btn sp" v-if="index === 0">
                <a href="javascript:void(0)" @click="onShoppingDetailClicked(ship)" class="linkBtn">注文内容詳細</a>
              </div>
              <div class="btn sp" v-if="index === 0 && ship.user_receipt_id===123456">
                <a href="javascript:void(0)" @click="receiptDownload(ship)" class="linkBtn">領収書ダウンロード</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="button_wrap">
      <p>発送済みも含めたご注文商品はこちらのボタンを押してください。</p>
      <router-link :to="{ name: 'UsersCustomerShoppingHistories'}" class="submit light skeleton">オンラインショップ購入履歴
      </router-link>
    </div>

    <ShoppingModal :shopping_id="shopping_id" v-if="shopping_id" v-on:canceled="shopping_id=null"/>


  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import ShoppingModal from '@/views/shop/_shopping_modal.vue'

export default {
  mixins: [Libraries],
  components: {
    ShoppingModal,
  },
  data() {
    return {
      shopping_id: null
    }
  },
  props: {
    shoppings: null
  },
  methods: {
    onShoppingDetailClicked(shopping) {
      this.shopping_id = shopping.id
    },
    receiptDownload(shopping) {
      location.href = `${this.env.api_base_url}users/receipt_download.pdf?id=${shopping.user_receipt_id}&token=${this.current_user.api_token}`
    },
  }
}
</script>