<template>
  <div id="menu06" class="cts_box invoice anchor_point" v-if="fetch_data">
    <h2 class="sub_ttl"><span>お支払い履歴・領収書PDFダウンロード</span></h2>
    <p class="blue bold">領収書の宛名に記載する名称を登録・変更する場合は以下の入力欄に記入の上で「宛名を登録する」ボタンを押してください。</p>
    <div class="receipt">
      <form action="" class="flex">
        <p>領収書宛名</p>
        <div class="input"><input type="text" v-model="current_user.receipt_name"></div>
        <div class="btn">
          <input type="button" class="submit light skeleton" v-on:click="receiptNameUpdate()" value="宛名を登録する">
        </div>
      </form>
    </div>
    <div class="bdr_box payment_history">
      <h3 class="blue bold">表示内容の絞り込み</h3>
      <h4>期間で絞り込み</h4>
      <div class="date_calender flex">
        <div class="calender">
          <Datepicker ref="start_date" :language="ja" :format="date_picker_format" :clear-button="false"
                      v-model="start_date" v-on:closed="fetchData"/>
          <button class="cal_btn" @click="openPicker('start_date')"></button>
        </div>
        <p>〜</p>
        <div class="calender">
          <Datepicker ref="end_date" :language="ja" :format="date_picker_format" :clear-button="false"
                      v-model="end_date" v-on:closed="fetchData"/>
          <button class="cal_btn" @click="openPicker('end_date')"></button>
        </div>
      </div>
      <h4>チェックを入れた内容で表示を絞り込みます。</h4>
      <div class="radio">
        <input id="radio01" type="radio" name="表示内容" v-model="receipt_type" value="all" v-on:change="fetchData">
        <label for="radio01">すべて表示</label>

        <input id="radio02" type="radio" name="表示内容" v-model="receipt_type" value="subscription"
               v-on:change="fetchData">
        <label for="radio02">入塾金・月謝</label>

        <input id="radio04" type="radio" name="表示内容" v-model="receipt_type" value="workshop" v-on:change="fetchData">
        <label for="radio04">研修会・合宿ツアー</label>

        <input id="radio05" type="radio" name="表示内容" v-model="receipt_type" value="monthly_live"
               v-on:change="fetchData">
        <label for="radio05">月例会</label>

        <input id="radio06" type="radio" name="表示内容" v-model="receipt_type" value="other" v-on:change="fetchData">
        <label for="radio06">その他</label>
        <!--
        <input id="radio07" type="radio" name="表示内容" v-model="receipt_type" value="shop" v-on:change="fetchData">
        <label for="radio07">オンラインショップ</label>
        -->
      </div>
    </div>

    <div class="check_list flex">
      <div class="checkbox">
        <input id="check02" type="checkbox" v-model="all_checked" v-on:change="allCheckClicked">
        <label for="check02">すべてにチェックを入れる</label>
      </div>
      <a href="javascript:void(0);" v-on:click="allCheckClearClicked">すべてのチェックをはずす</a>

      <div class="button_wrap">
        <a href="javascript:void(0);" class="submit light skeleton" v-bind:disabled="checkedCount() === 0"
           v-on:click="checkedReceiptsDownload()">チェックしたPDFを一括ダウンロード</a>
      </div>
    </div>

    <div class="invoice_list">
      <div class="header flex">
        <div class="data">日付</div>
        <div class="proviso">但し書き</div>
        <div class="price">金額</div>
        <div class="btn">領収書・明細書ダウンロード</div>
      </div>

      <div class="scrl_box">
        <div class="inner" v-for="receipt in fetch_data.receipts" :key="receipt.id">
          <ul>
            <li class="flex">
              <div class="data">
                <div class="checkbox">
                  <input v-bind:id="'receipt-' + receipt.id" type="checkbox" v-model="receipt.checked">
                  <label v-bind:for="'receipt-' + receipt.id"><span
                      class="pc">{{ moment(receipt.publish_date, 'YYYY年MM月DD日') }}</span><span
                      class="sp">まとめてダウンロードの場合はチェック</span></label>
                </div>
              </div>
              <div class="data_sp sp">
                <div class="ttl">日付</div>
                <p>{{ moment(receipt.publish_date, 'YYYY年MM月DD日') }}</p></div>
              <div class="proviso">
                <div class="ttl">但し書き</div>
                <p>{{ receipt.title }}</p></div>
              <div class="price">
                <div class="ttl">金額</div>
                <p>¥ {{ delimited(receipt.price) }}</p></div>
              <div class="btn">
                <a href="javascript:void(0);" class="linkBtn" v-bind:disabled="receipt.price === 0" v-on:click="receiptDownload(receipt)">PDFダウンロード</a>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import Datepicker from 'vuejs-datepicker';
import {ja} from 'vuejs-datepicker/dist/locale'

export default {
  mixins: [Libraries],
  components: {
    Datepicker,
  },
  data() {
    return {
      fetch_data: null,
      ja: ja,
      date_picker_format: 'yyyy-MM-dd',
      start_date: null,
      end_date: null,
      receipt_type: 'all',
      all_checked: false
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    openPicker: function (ref) {
      if (this[ref]) {
        this[ref] = null
        this.fetchData()
      } else {
        if (!this.$refs[ref].isOpen) {
          this.$refs[ref].$el.querySelector("input").focus();
          this.$refs[ref].showCalendar();
        }
      }
    },

    allCheckClicked() {
      this.fetch_data.receipts.forEach((receipt) => {
        receipt.checked = this.all_checked
      })
    },
    allCheckClearClicked() {
      this.all_checked = false
      this.allCheckClicked();
    },
    checkedCount() {
      let count = 0
      this.fetch_data.receipts.forEach((receipt) => {
        if (receipt.checked) count++
      })
      return count
    },

    checkedReceiptsDownload() {
      let ids = []
      this.fetch_data.receipts.forEach((receipt) => {
        if (receipt.checked) ids.push(receipt.id)
      })
      if (ids.length === 0) return
      location.href = `${this.env.api_base_url}users/receipts_download.pdf?ids=${ids}&token=${this.current_user.api_token}`
    },

    receiptDownload(receipt) {
      location.href = `${this.env.api_base_url}users/receipt_download.pdf?id=${receipt.id}&token=${this.current_user.api_token}`
    },

    receiptNameUpdate() {
      this.startAjax()
      this.axios
          .post(`${this.env.api_base_url}users/update.json`, {
            user: {
              receipt_name: this.current_user.receipt_name
            }
          })
          .then(response => {
            //console.log(response.data)
            this.saveSession(response.data.user);
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
            // alert('更新しました');
          })
    },
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}users/receipts.json`, {
            params: {
              start_date: this.start_date,
              end_date: this.end_date,
              filter: this.receipt_type,
            }
          })
          .then(response => {
            this.fetch_data = response.data
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },
  }
}
</script>
