<template>
  <div id="menu05" class="cts_box anchor_point">
    <h2 class="sub_ttl"><span>お支払い情報</span></h2>
    <table class="payment_detail">
      <tbody>
      <tr class="head">
        <th>お客様のクレジットカード</th>
        <th>有効期限</th>
        <th>カード名義</th>
      </tr>
      <tr>
        <td><h3 class="sp">お客様のクレジットカード</h3>末尾 {{ current_user.credit_pan }} のクレジットカード</td>
        <td><h3 class="sp">有効期限</h3>{{ moment(current_user.credit_expired_at, 'YYYY/MM') }}</td>
        <td><h3 class="sp">カード名義</h3>************</td>
      </tr>
      </tbody>
    </table>

    <div class="button_wrap">
      <p>お支払いに関する情報の変更はこちらのボタンを押してください。</p>
      <a href="javascript:void(0)" class="submit light skeleton" v-on:click="$emit('update')">支払い情報を変更する</a>
      <div class="paygent flex">
        <img src="/common/images/paygent_logo.png" alt="PAYGENT">
        <p>
          クレジットカード決済サービス会社（PAYGENT）の決済画面に移動します。PAYGENTは、NTTデータと三菱UFJニコスの合弁による国内大手決済サービス会社です。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries]
}
</script>
