<template>
  <div class="cts_box">
    <h2 class="sub_ttl"><span>プロフィール</span></h2>

    <div class="profile">
      <dl class="flex">
        <dt>氏名</dt>
        <dd>{{ current_user.last_name }} {{ current_user.first_name }}</dd>
      </dl>
      <dl class="flex">
        <dt>性別</dt>
        <dd>{{ {secret: 'その他', male: '男性', female: '女性'}[current_user.gender] }}</dd>
      </dl>
      <dl class="flex">
        <dt>生年月日</dt>
        <dd>{{ moment(current_user.birthday, 'YYYY年MM月DD日') }}</dd>
      </dl>

      <template v-if="current_user.rank === 'rank_student_month'">
        <dl class="flex">
          <dt>卒業見込み年/日</dt>
          <dd>{{ current_user.student_graduation_date_year }}年 {{ current_user.student_graduation_date_month }}月</dd>
        </dl>
        <dl class="flex">
          <dt>学校が発行したメールアドレス</dt>
          <dd>{{ current_user.student_not_email ? '持っていません' : current_user.student_email }}</dd>
        </dl>
        <dl class="flex" v-if="current_user.student_not_email">
          <dt>学生証の登録</dt>
          <dd>アップロード済み</dd>
        </dl>
      </template>

      <dl class="flex">
        <dt>住所</dt>
        <dd>〒{{ current_user.zip }}<br>{{ current_user.address1 }} {{
            current_user.address2
          }}<br>{{ current_user.address3 }}
        </dd>
      </dl>
      <dl class="flex">
        <dt>電話番号</dt>
        <dd>{{ current_user.phone_number }}</dd>
      </dl>
      <dl class="flex">
        <dt>メールアドレス</dt>
        <dd>{{ current_user.email }}</dd>
      </dl>
      <dl class="flex" v-if="false">
        <dt>第二<br class="sp">メールアドレス</dt>
        <dd>設定されていません</dd>
      </dl>
      <dl class="flex">
        <dt>パスワード</dt>
        <dd>セキュリティ保持のため非表示</dd>
      </dl>
      <dl class="flex">
        <dt>送付先住所</dt>
        <dd>〒{{ current_user.opt_zip }}<br>{{ current_user.opt_address1 }} {{
            current_user.opt_address2
          }}<br>{{ current_user.opt_address3 }}
        </dd>
      </dl>
    </div>

    <div class="button_wrap">
      <p>プロフィール情報の変更はこちらのボタンを押してください。</p>
      <router-link :to="{name: 'UsersCustomerAccountEdit'}" class="submit light skeleton">プロフィールの編集</router-link>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries]
}
</script>