<template>
  <div class="cts_box">
    <h2 class="min_ttl"><span>複眼経済塾からの退塾はこちらからお手続きください。</span></h2>
    <router-link :to="{name: 'UsersCustomerWithdrawal'}" class="link_txt">複眼経済塾からの退塾について</router-link>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries]
}
</script>