<template>
  <div class="cts_box claim">
    <h2 class="sub_ttl"><span>次回の月謝ご請求内容</span></h2>
    <p class="lead_txt">
      次回の複眼経済塾の月謝お支払いに関するお知らせです。<br>
      次回の月謝にポイントを利用する場合は「ポイント利用額」に利用ポイントを入力し「ポイントを利用する」ボタンを押してください。<br>
      <span class="red bold" >※次回の月謝お支払いの前に、休塾・退塾申請やクラス変更を行った場合、設定したポイントは返還されます</span>
    </p>
    <table class=""><!-- ポイント利用できない場合はclass="point_none" を追加 -->
      <tbody>
      <tr class="head">
        <th>次回請求日</th>
        <th>請求金額</th>
        <th>ポイント利用額（1ポイント＝1円）</th>
      </tr>
      <tr>
        <td class="date">{{ moment(fee.next_payment_date, 'YYYY年MM月DD日') }}</td>
        <td class="price">¥ {{ delimited(fee.payment) }}<span v-if="fee.payment_yearly">年額払い</span></td>
        <td class="point flex">
          <p>ご利用ポイントを入力</p>
          <div class="point_input">
            <input type="text" v-model="use_fee_points"
                   @input="use_fee_points = replaceFullToNumber(use_fee_points)"
                   v-bind:disabled="point.total_use_fee_points === 0 || !fee.point_useable"
                   maxlength="6" class="point">

            <!--<input type="text" value="1000" maxlength="5" class="point" disabled> ポイント利用できない場合-->
            <p>{{ delimited(Math.min(point.total_use_fee_points, fee.payment)) }}ポイントまで利用可能</p>
          </div>
          <p class="bold">ポイント</p>
        </td>
      </tr>
      <tr>
        <td colspan="3" class="btn" v-if="fee.point_useable">
          <input type="submit" class="submit light skeleton" value="ポイントを利用する" @click="usePointClicked">
        </td>
        <td colspan="3" class="btn" v-else>
          <input type="submit" class="submit light skeleton" value="ポイントは設定済み" disabled>
        </td>
      </tr>
      <tr>
        <td colspan="3" class="claim_total">ポイント適用後の請求金額<span>¥ {{ delimited(total_price) }}</span></td>
      </tr>
      </tbody>
    </table>
    <template v-if="false">
      <div class="status">
        <h4>現在のステータス</h4>
        <p class="status_now blue bold">銀行振込・コンビニ払い待ち</p>
      </div>
      <p>
        クレジットカード決済以外のお支払い方法を選択されている場合は、それぞれのお支払い方法に対応する支払票の発行が必要となります。以下のボタンを押して、支払票の発行処理を行ってください。（外部決済会社のサイトへ移動します）
      </p>
      <div class="button_wrap">
        <a href="#" class="submit basic">支払票の発行</a>
        <p>ペイジェント（外部決済会社）のサイトに移動します</p>
      </div>
    </template>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  data() {
    return {
      total_price: this.fee.payment - this.use_fee_points
    }
  },

  props: {
    fee: null,
    point: null,
    use_fee_points: null
  },
  methods: {
    usePointClicked() {
      if (isNaN(this.use_fee_points) || !this.use_fee_points) return

      if (this.use_fee_points > this.point.total_use_fee_points) {
        this.use_fee_points = this.point.total_use_fee_points
      }
      if (this.use_fee_points > this.fee.payment) {
        this.use_fee_points = this.fee.payment
      }
      this.total_price = this.fee.payment - this.use_fee_points

      // 決済時間中であれば決済が終わるまで変更出来ない 1日の 0時〜2時まで
      const now = new Date()
      if (now.getDate() === 1 && now.getHours() >= 0 && now.getHours() <= 2) {
        alert('ただ今、月謝の決済中です。処理が終わるまで月謝へのポイントの利用はできません。')
        return
      }

      if (!confirm(`次回の月謝のお支払いで、${this.delimited(this.use_fee_points)}ポイントを利用しますか？`)) {
        return
      }
      this.axios
          .post(`${this.env.api_base_url}users/use_fee_points.json`, {
            use_fee_points: this.use_fee_points
          })
          .then(response => {
            //console.log(response.data)
            // this.saveSession(response.data.user);
            this.fee.point_useable = false
            this.point.total_use_fee_points = response.data.total_use_fee_points
            // this.$router.push({name: 'UsersCustomerAccount'})
          })
          .catch(error => {
            this.errors = true
            if (error.response.data) {
              if (error.response.data.errors) {
                this.errors = true
                this.scrollTo('#form-top')
              }
              if (error.response.data.validation_errors) {
                this.validation_errors = error.response.data.validation_errors
              }
            }
          })
          .finally(() => {
            this.finishAjax()
            // alert('更新しました');
          })
    },
  },
}
</script>
