<template>
  <div class="cts_box stand-by">
    <h2>{{ moment(current_user.join_at, 'YYYY年M') }}月期生の入塾手続きありがとうございました。<br>入塾日<span>（{{ moment(current_user.join_at, 'YYYY年M月D日') }}）</span>までしばらくお待ちください。</h2>
    <div class="inner">
      <h3>{{ moment(current_user.join_at, 'YYYY年M') }}月期生の入塾日（講義開始日）は、{{ moment(current_user.join_at, 'YYYY年M月D日') }}となります</h3>
      <p>
        全コンテンツへのアクセスは{{ moment(current_user.join_at, 'YYYY年M月D日') }}（入塾日）から可能となります。<br>
        それまでの期間は、当該「仮マイページ」、「講義」の一部、「イベント申し込み」、「メディア」、「会社概要」、「ショップ」のみアクセス可能です。<br>
        マイページは{{ moment(current_user.join_at, 'YYYY年M月D日') }}（入塾日）に「正式版」に変わります。
      </p>
      <h3>入塾日までの間、予習動画をご覧いただけます</h3>
      <p>
        入塾日までの間、「講義」の
        「<router-link to="/sns/space/content/4-17">入塾式（入塾1年生のための動画）</router-link>」
        「<router-link to="/sns/space/content/4-19">複眼投資入門</router-link>」
        「<router-link to="/sns/space/content/4-28">5 whys 投資事始</router-link>」
        「<router-link to="/sns/space/content/4-48">ゼロからはじめる投資入門！</router-link>」
        がご覧いただけます。こちらの4つの講義を入塾までに予習としてご覧ください。メールでもご案内いたします。
      </p>
      <h3>月例会・研修会にお申し込みください</h3>
      <p>
        入塾日前に「月例会」「研修会」にお申し込みが可能です。「イベント申し込み」よりお申し込みください。
        定員になり次第締切となりますので、ぜひお早目にお申し込みください。
      </p>
      <h3 class="blue">※東洋経済新報社・会社四季報オンラインのオプションをお申込みの方</h3>
      <p class="mb0">
        会社四季報オンラインのオプションIDも、{{ moment(current_user.join_at, 'YYYY年M月D日') }}から利用可能となります。入塾日以降にID／仮パスワードを当マイページ内でご確認ください。
      </p>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries]
}
</script>