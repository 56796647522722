<template>

  <div class="cts_box sns_dashbord">
    <h2 class="sub_ttl"><span>各種設定と活動状況</span></h2>
    <template>
      <div id="menu02" class="anchor_point"></div>
      <h3 class="min_ttl"><span>あなたの複眼経済塾WEBサイト活動内容</span></h3>
      <p class="lead_txt">あなたの複眼経済塾WEBサイトの利用実績（投稿数・コメント数・いいねの数など）を数値化した合計スコアにもとづいて、順位の算出を行っています。<br><br>
        くわしくは
        <router-link :to="{name: 'UsersCustomerSnsDashboard', hash: '#menu07'}">各種設定と活動状況</router-link>
        をご覧ください。
      </p>
      <div class="bdr_box">
        <table class="sns_activities">
          <tbody>
          <tr class="header">
            <td>&nbsp;</td>
            <td>月間<span>{{ moment(sns.score.term_real_monthly.start_date, 'YYYY年') }}<br class="sp">{{ moment(sns.score.term_real_monthly.start_date, 'MM月') }}</span></td>
            <td>四半期<span>{{ moment(sns.score.term_real_quarter.start_date, 'YYYY年') }}<br class="sp">{{ moment(sns.score.term_real_quarter.start_date, 'MM月') }}〜{{ moment(sns.score.term_real_quarter.end_date, 'MM月') }}</span></td>
            <td>年間<span>{{ moment(sns.score.term_real_yearly.start_date, 'YYYY年') }}<br class="sp">1月〜12月</span></td>
            <td class="total">累計</td>
          </tr>
          <tr class="real_rank">
            <th><span class="icon_real_rank">順位</span></th>
            <td><span>{{ sns.score.term_real_monthly ? delimited(sns.score.term_real_monthly.rank) : '-' }}</span> 位</td>
            <td><span>{{ sns.score.term_real_quarter ? delimited(sns.score.term_real_quarter.rank) : '-' }}</span> 位</td>
            <td><span>{{ sns.score.term_real_yearly ? delimited(sns.score.term_real_yearly.rank) : '-' }}</span> 位</td>
            <td><span>-</span> 位</td>
          </tr>
          <tr class="real_score">
            <th><span class="icon_real_score">スコア合計</span></th>
            <td><span>{{ sns.score.term_real_monthly ? delimited(sns.score.term_real_monthly.score) : '-' }}</span> 点</td>
            <td><span>{{ sns.score.term_real_quarter ? delimited(sns.score.term_real_quarter.score) : '-' }}</span> 点</td>
            <td><span>{{ sns.score.term_real_yearly ? delimited(sns.score.term_real_yearly.score) : '-' }}</span> 点</td>
            <td><span>{{ delimited(sns.score.total_score) }}</span> 点</td>
          </tr>
          <tr>
            <th><span class="icon_real_post">SNSへの<br class="sp">投稿</span></th>
            <td><span>{{ sns.score.term_real_monthly ? delimited(sns.score.term_real_monthly.post_score) : '-' }}</span> 点</td>
            <td><span>{{ sns.score.term_real_quarter ? delimited(sns.score.term_real_quarter.post_score) : '-' }}</span> 点</td>
            <td><span>{{ sns.score.term_real_yearly ? delimited(sns.score.term_real_yearly.post_score) : '-' }}</span> 点</td>
            <td><span>{{ delimited(sns.score.total_post_score) }}</span> 点</td>
          </tr>
          <tr>
            <th><span class="icon_real_comment_post">コメントを<br class="sp">投稿</span></th>
            <td><span>{{ sns.score.term_real_monthly ? delimited(sns.score.term_real_monthly.comment_score) : '-' }}</span> 点</td>
            <td><span>{{ sns.score.term_real_quarter ? delimited(sns.score.term_real_quarter.comment_score) : '-' }}</span> 点</td>
            <td><span>{{ sns.score.term_real_yearly ? delimited(sns.score.term_real_yearly.comment_score) : '-' }}</span> 点</td>
            <td><span>{{ delimited(sns.score.total_comment_score) }}</span> 点</td>
          </tr>
          <tr>
            <th><span class="icon_real_iine_post">いいね！を<br class="sp">追加</span></th>
            <td><span>{{ sns.score.term_real_monthly ? delimited(sns.score.term_real_monthly.like_score) : '-' }}</span> 点</td>
            <td><span>{{ sns.score.term_real_quarter ? delimited(sns.score.term_real_quarter.like_score) : '-' }}</span> 点</td>
            <td><span>{{ sns.score.term_real_yearly ? delimited(sns.score.term_real_yearly.like_score) : '-' }}</span> 点</td>
            <td><span>{{ delimited(sns.score.total_like_score) }}</span> 点</td>
          </tr>
          <tr>
            <th><span class="icon_real_comment">コメントが<br class="sp">追加</span></th>
            <td><span>{{ sns.score.term_real_monthly ? delimited(sns.score.term_real_monthly.commented_score) : '-' }}</span> 点</td>
            <td><span>{{ sns.score.term_real_quarter ? delimited(sns.score.term_real_quarter.commented_score) : '-' }}</span> 点</td>
            <td><span>{{ sns.score.term_real_yearly ? delimited(sns.score.term_real_yearly.commented_score) : '-' }}</span> 点</td>
            <td><span>{{ delimited(sns.score.total_commented_score) }}</span> 点</td>
          </tr>
          <tr>
            <th><span class="icon_real_iine">いいね！<br class="sp">された</span></th>
            <td><span>{{ sns.score.term_real_monthly ? delimited(sns.score.term_real_monthly.liked_score) : '-' }}</span> 点</td>
            <td><span>{{ sns.score.term_real_quarter ? delimited(sns.score.term_real_quarter.liked_score) : '-' }}</span> 点</td>
            <td><span>{{ sns.score.term_real_yearly ? delimited(sns.score.term_real_yearly.liked_score) : '-' }}</span> 点</td>
            <td><span>{{ delimited(sns.score.total_liked_score) }}</span> 点</td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>

    <div id="menu03" class="anchor_point"></div>
    <h3 class="min_ttl"><span>フォロー中・フォロワーの塾生</span></h3>
    <p class="follow">現在フォロー中の塾生<span>{{ delimited(sns.follows_count) }}</span>人</p>
    <ul class="follow_list flex"><!--14件まで-->
      <li v-for="user in sns.follows" :key="user.id">
        <router-link :to="{ name: 'SnsTimeline', params: {user_id: user.id}}">
          <img v-bind:src="user.profile_image_url" alt="">
        </router-link>
      </li>
      <li class="more" v-if="sns.follows_count > 14"></li><!--14件以上になったら表示-->
    </ul>
    <p class="follower">あなたをフォローしている塾生<span>{{ delimited(sns.followers_count) }}</span>人</p>
    <ul class="follow_list flex"><!--14件まで-->
      <li v-for="user in sns.followers" :key="user.id">
        <router-link :to="{ name: 'SnsTimeline', params: {user_id: user.id}}">
          <img v-bind:src="user.profile_image_url" alt="">
        </router-link>
      </li>
      <li class="more" v-if="sns.followers_count > 14"></li><!--14件以上になったら表示-->
    </ul>

    <div class="button_wrap">
      <p>その他、塾生の検索・通知メールの設定など詳細は各種設定と活動状況で設定できます。</p>
      <router-link :to="{name: 'UsersCustomerSnsDashboard'}" class="submit light skeleton">各種設定と活動状況</router-link>
    </div>

  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  props: {
    sns: null
  },
  methods: {
    rankClass(rank, term) {
      if (rank && rank.old_rank && rank.rank < rank.old_rank) {
        return `up_${term}`
      } else if (rank && rank.old_rank && rank.rank > rank.old_rank) {
        return `down_${term}`
      } else if (rank && rank.old_rank && rank.rank === rank.old_rank) {
        return 'no_change'
      }
      return ''
    }
  }
}
</script>