<template>
  <div class="cts_box">
    <h2 class="sub_ttl"><span>会社四季報ONLINE</span></h2>
    <div class="shikihou_plan flex">
      <h3>現在ご利用中のプラン</h3>
      <span v-if="shikiho_online.plan === 'plan_basic'">ベーシックプラン</span>
      <span v-else>プレミアムプラン</span>
    </div>
    <p class="lead_txt">
      以下の初期アクセス情報を使って「会社四季報ONLINE」にログイン可能です。
    </p>

    <div class="shikihou_online_first bdr_box">
      <h3 class="min_ttl"><span>「会社四季報ONLINE」アクセス情報</span></h3>
      <p class="lead_txt">
        <template v-if="shikiho_online.account_change_at">
          紐づけが完了しましたので、下記に表示されております「ユーザーID」とご自身で設定されたパスワードでログインができます。
        </template>
        <template v-else>
          下の「会社四季報ONLINE」ボタンより「会社四季報ONLINE」を表示した後、以下の初期アクセス情報を使用してログインしてください。<br>
          ログイン後、初期パスワードは必ず変更するようにしてください。変更後はこちらのパスワードはご利用いただけなくなります。
        </template>
      </p>
      <dl class="flex">
        <dt>ログイン用メールアドレス</dt>
        <dd>{{ shikiho_online.account }}</dd>
      </dl>
      <dl class="flex">
        <dt>パスワード</dt>
        <dd class="password flex">
          <span v-if="!show_password">＊＊＊＊＊＊＊＊</span>
          <template v-if="!shikiho_online.account_change_at">
            <span v-if="show_password">{{ shikiho_online.password }}</span>
            <div class="passwd_switch pc" v-bind:class="{active: show_password}"
                 v-on:click="show_password = !show_password"></div><!-- active付与でアイコンとテキストが変わります-->
          </template>
        </dd>
      </dl>
      <div class="passwd_switch sp" v-bind:class="{active: show_password}"
           v-on:click="show_password = !show_password"></div><!-- SP用 active付与でアイコンとテキストが変わります-->

      <div class="button_wrap">
        <div style="text-align: center" class="mb20">
          <p class="red bold marker">※ログイン後にメールアドレスのご確認をお願いいたします。セキュリティ向上のためパスワードは一定期間ごとに変更をお願いします。</p>
        </div>
        <a href="https://shikiho.toyokeizai.net/" target="_blank" class="submit basic arrow">会社四季報ONLINE</a>
        <p class="blue bold">「会社四季報ONLINE」のページを表示します。</p>
      </div>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  data() {
    return {
      show_password: false
    }
  },
  props: {
    shikiho_online: null
  }
}
</script>