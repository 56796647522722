<template>
  <div class="cts_box">
    <h2 class="min_ttl"><span>複眼経済塾からログアウトする場合はこちら</span></h2>
    <a href="javascript:void(0);" v-on:click="logoutClicked" class="link_txt">複眼経済塾からログアウトする</a>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries'

export default {
  mixins: [Libraries],
  methods: {
    logoutClicked() {
      this.axios.delete(`${this.env.api_base_url}users/sign_out.json`, {})
          .then(() => {
            this.removeSession();
            this.setFlash('ログアウトしました。')
            this.$router.push({
              name: 'UsersSignIn',
              //query: {sign_out: true}
            })
          })
          .catch((error) => {
            console.log(error)
          });
    },
  }

}
</script>