<template>
  <div class="cts_box friend">
    <h2 class="sub_ttl"><span>お友達紹介プログラム</span></h2>
    <p class="lead_txt">
      下記の利用規約に同意の上、「お友達紹介リンクを作成する」ボタンを押すと、複眼経済塾へのご案内を印したテキストと申し込みページのURLが発行されます。内容をコピーした後メール等に貼り付けて、複眼経済塾を紹介したいお友達に送付してください。発行されたURLからお友達が複眼経済塾に入塾すると、所属したクラスに応じたポイントの還元が受けられます。
    </p>
    <div class="bdr_box">
      <h3 class="min_ttl"><span>「お友達紹介プログラム」について</span></h3>
      <p class="lead_txt">
        アフィリエイトプログラムに関する注意事項などを記載。複素数体であれば、任意のCM-タイプの A は、実際、数体である定義体（英語版）(FIELD OF
        DEFINITION)を持っている。自己準同型環の可能なタイプは、対合（ロサチの対合（英語版）(RO
      </p>
      <h4 class="bold">「お友達紹介プログラム」利用規約</h4>
      <div class="pp_box">
        <p>本規約は、複眼経済塾株式会社(以下「当社」といいます)が提供する有料塾生サ－ビス『複眼経済塾』（以下「本サービス」といいます）に関して、当社と塾生との間の一切の関係に適用されます。</p>
        <h5>第1条 (コンテンツ販売サービス）</h5>
        <p>
          1．本サービスとは、当社が提供する『複眼経済塾』の塾生向けコンテンツ有料販売サービスをいい、次項に定める概要によるものとし、且つその詳細は第2条第1項に定める諸規定に従います。<br>
          2．当社は、塾生が登録したＩＤおよびパスワードでアクセス可能な『複眼経済塾ウェブサイト（以下、「本サイト」といいます)』に、コンテンツをアップロードし、塾生は各自これを月謝を支払うことで配信を受けるものとします。<br>
          3．コンテンツは月謝を納入している期間において視聴が可能です。<br>
          4．当社は、塾生の登録情報の変更、自動課金の停止、復旧処理を完了した場合、塾生に電子メールもって通知するものとします。
        </p>
        <p>本規約は、複眼経済塾株式会社(以下「当社」といいます)が提供する有料塾生サ－ビス『複眼経済塾』（以下「本サービス」といいます）に関して、当社と塾生との間の一切の関係に適用されます。</p>
        <h5>第1条 (コンテンツ販売サービス）</h5>
        <p>
          1．本サービスとは、当社が提供する『複眼経済塾』の塾生向けコンテンツ有料販売サービスをいい、次項に定める概要によるものとし、且つその詳細は第2条第1項に定める諸規定に従います。<br>
          2．当社は、塾生が登録したＩＤおよびパスワードでアクセス可能な『複眼経済塾ウェブサイト（以下、「本サイト」といいます)』に、コンテンツをアップロードし、塾生は各自これを月謝を支払うことで配信を受けるものとします。<br>
          3．コンテンツは月謝を納入している期間において視聴が可能です。<br>
          4．当社は、塾生の登録情報の変更、自動課金の停止、復旧処理を完了した場合、塾生に電子メールもって通知するものとします。
        </p>
      </div>
      <div class="button_wrap mt20">
        <form action="">
          <div class="checkbox pp_agree">
            <input id="check01" type="checkbox">
            <label for="check01">「お友達紹介プログラム」利用規約に同意する</label>
          </div>
          <a href="#" class="submit basic disabled">お友達紹介リンクを作成する</a>
          <p class="blue bold sp_alnC">ダイアログボックスが開きます</p>
        </form>
      </div>
    </div>

    <div class="button_wrap">
      <p>お友達紹介の結果などを確認するにはこちらのボタンを押してください。</p>
      <a href="#" class="submit light skeleton">レポートを見る</a>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries]
}
</script>