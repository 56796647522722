<template>
  <div id="coupon" class="cts_box anchor_point">
    <h2 class="sub_ttl"><span>現在所有しているクーポン</span></h2>
    <p class="lead_txt">
      現在以下のクーポンが利用できます。クーポンが適用中になっていることをご確認ください。
    </p>

    <div class="scrl_box coupon workshop">
      <div class="inner">
        <ul class="coupon_list">
          <li class="flex" v-for="coupon in coupons" :key="coupon.id">
            <div class="thumb"><img v-bind:src="coupon.image_file_url" alt=""></div>
            <div class="detail">
              <div class="head flex">
                <h3>{{ coupon.title }}</h3>
                <div class="dead_line">有効期限：<span>{{ moment(coupon.close_date, 'YYYY年MM月DD日') }}</span></div>
              </div>
              <p>
                {{ coupon.description }}
              </p>
            </div>
            <div class="btn_wrap">
              <router-link :to="{path: coupon.use_url}" class="btn">クーポンを利用する</router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  props: {
    coupons: null
  }
}
</script>