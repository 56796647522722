<template v-if="isUserSleep()">
  <div class="cts_box">
    <div class="button_wrap">
      <p class="red bold">複眼経済塾に復塾される場合はこちらからお手続きいただけます。<br>
        {{ current_user.sns_user_name }}様は <span class="marker">{{ current_user.rank_label }}・{{ current_user.payment_monthly ? '月額払い' : '年額払い' }} </span>をご利用でしたので、
        <span class="marker">同様のクラス・お支払い方法に復塾</span>となります。<br>
        月初にお支払いいただいた休塾会費との差額を日割り決済させていただきます。</p>
      <a href="javascript:void(0)" @click="cancelSleep()" class="submit basic">差額決済して復塾する</a>
      <p style="color: #666">
        クラス・お支払い方法を変更されたい場合は、一旦現在のクラスで復帰手続き後に、<br>
        すぐにご自身で、復塾後のマイページ内にて変更ができます。
      </p>
    </div>
    <div class="button_wrap" v-if="current_user.withdrawal_request">
      <a href="javascript:void(0)" class="submit light"
         @click="cancelMembershipWithdrawal">退塾申請をキャンセル</a>
      <p class="lead_txt">
        複眼経済塾への退塾申請をキャンセルします。
      </p>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  methods: {
    cancelSleep() {
      if (!confirm('複眼経済塾の利用を再開します。よろしいですか？')) {
        return
      }
      // ランク変更キャンセル
      this.axios
          .post(`${this.env.api_base_url}users/cancel_seep.json`)
          .then((response) => {
            if (response.data.url) {
              document.location.href = response.data.url
            } else {
              this.$router.push({name: 'UsersCustomerSleepCancelFinish'})
            }
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
          })
    },
    cancelMembershipWithdrawal() {
      if (!confirm('退塾の申請をキャンセルしますか？')) {
        return
      }
      this.axios
          .post(`${this.env.api_base_url}users/cancel_membership_withdrawal.json`, {})
          .then(() => {
            // console.log(response.data)
            const message = `退塾の申請をキャンセルしました。\nマイページへ戻ります。`
            alert(message)
            // マイページへ
            //this.$router.push({name: 'UsersCustomerAccount'})
            location.reload()
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
          })
    }
  }
}
</script>